<template>
  <a-modal
    title="新增免审主体"
    :visible="visible"
    :mask-closable="false"
    @ok="handleOk"
    @cancel="handleCancel"
  >
    <div class="content">
      <a-form-model
        class="form"
        ref="form"
        :model="form"
        :rules="rules"
        :label-col="{span: 3}"
        :wrapper-col="{span: 21}"
      >
        <a-form-model-item label="主体" prop="principal_id">
          <a-select
            v-model="form.principal_id"
            placeholder="请选择主体"
            :default-active-first-option="false"
            show-search
            option-filter-prop="children"
            style="width: 100%;"
            @change="handlePrincipalChange"
          >
            <a-select-option
              v-for="item in principalList"
              :key="item.id"
              :value="item.id"
            >
              {{ item.principal_name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item v-if="form.principal_id" :wrapper-col="{span: 21, offset: 3}">
          <div class="principal">
            <div class="principal__avatar">
              <a-avatar
                icon="user"
                shape="square"
                :size="64"
                :src="`${ossHost3}/${principal.avatar}`"
              />
            </div>
            <div class="principal__content">
              <div class="principal__name">
                {{ principal.principal_name }}
              </div>
              <div class="principal__id">
                ID：{{ principal.id }}
              </div>
            </div>
          </div>
        </a-form-model-item>
        <a-form-model-item :wrapper-col="{span: 21, offset: 3}">
          <p class="tips">开启后当前待审和后续新添加媒体号都会免审通过。</p>
        </a-form-model-item>
      </a-form-model>
    </div>
  </a-modal>  
</template>

<script>
const ossHost3 = process.env.VUE_APP_OSS_HOST3;

export default {
  name: "AddAuditModal",
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    form: {
      type: Object,
      default() {
        return {};
      }
    },
    principalList: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      ossHost3,
      rules: {
        principal_id: [
          { required: true, message: '主体不能为空', trigger: 'change' }
        ]  
      },
      principal: {}
    }
  },
  watch: {
    visible(newVal, oldVal) {
      if (!newVal) {
        this.$refs.form.resetFields();
        this.principal = {};
      }
    }
  },
  methods: {
    handlePrincipalChange(val) {
      const index = this.principalList.findIndex(item => item.id === val);
      this.principal = this.principalList[index];
    },
    handleOk() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.$emit("ok", {...this.form});
        } else {
          return false;
        }
      });
    },
    handleCancel() {
      this.$emit("cancel");
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  .principal {
    display: flex;
    align-items: center;

    &__avatar {
      width: 64px;
    }

    &__content {
      flex: 1;
      padding: 0 0 0 16px;
    }

    &__name {
      margin: 0 0 8px;
      font-size: 16px;
      font-weight: bold;
      line-height: 1.5;
    }

    &__id {
      line-height: 1.5;
    }
  }

  .tips {
    margin: 0;
    color: #ff7875;
    line-height: 1.5;
  }
}
</style>
