<template>
  <section class="page">
    <div class="page-header mb-16">
      <h1 class="title">经销商添加媒体号免审</h1>
      <div class="tips-wrap">
        <p class="tips">
          开启后，该主体下所有经销商当前待审和后续新添加媒体号都自动通过，无需人工审核（校验中的媒体号校验完成后也会免审通过），有10分钟延迟。
        </p>
        <a-button type="primary" @click="jumpTopPage">
          操作日志
        </a-button>
      </div>
    </div>

    <div class="config-wrap">
      <a-spin :spinning="loading">
        <a-row :gutter="16">
          <a-col :span="colSpan">
            <div class="config-item">
              <a-button
                class="add-btn"
                type="dashed"
                icon="plus"
                @click="openAddModal"
              >
                新增免审主体
              </a-button>
            </div>
          </a-col>
          <a-col :span="colSpan" v-for="(item, index) in list" :key="index">
            <div class="config-item">
              <a-card hoverable>
                <div class="principal">
                  <div class="principal__avatar">
                    <a-avatar
                      icon="user"
                      shape="square"
                      :size="64"
                      :src="`${ossHost3}/${item.avatar}`"
                    />
                  </div>
                  <div class="principal__content">
                    <div class="principal__name">
                      {{ item.principal_name || '-' }}
                    </div>
                    <div class="principal__id">
                      ID：{{ item.principal_id || '-' }}
                    </div>
                  </div>
                </div>
                <template class="ant-card-actions" slot="actions">
                  <a-button
                    class="ant-btn-danger"
                    type="link"
                    @click="openRemoveModal(item)"
                  >
                    取消免审
                  </a-button>
                </template>
              </a-card>
            </div>
          </a-col>
        </a-row>
      </a-spin>
    </div>

    <AddAuditModal
      :visible="addVisible"
      :form="addForm"
      :principal-list="principalList"
      @ok="handleAddOk"
      @cancel="handleAddCancel"
    />

    <RemoveAuditModal
      :visible="removeVisible"
      :principal="selectedPrincipal"
      @ok="handleRemoveOk"
      @cancel="handleRemoveCancel"
    />
  </section>  
</template>

<script>
import resizeMixin from "@/mixins/dataTableMixin";
import AddAuditModal from "./components/AddAuditModal";
import RemoveAuditModal from "./components/RemoveAuditModal";
import auditApi from "@/api/auditManage";
import trainingApi from "@/api/training_list";

const ossHost3 = process.env.VUE_APP_OSS_HOST3;

export default {
  name: "auditConfig",
  mixins: [resizeMixin],
  components: {
    AddAuditModal,
    RemoveAuditModal
  },
  data() {
    this.$_resizeHandler = () => {
      this.setColSpan();
    };

    return {
      colSpan: 12,
      ossHost3,
      loading: false,
      list: [],
      selectedPrincipal: {},
      principalList: [],
      addVisible: false,
      addForm: {
        principal_id: undefined
      },
      removeVisible: false
    };
  },
  created() {
    this.setColSpan();
    this.fetchAuditConfigList();
  },
  methods: {
    setColSpan() {
      const width = document.documentElement.offsetWidth;

      if (width < 1200) {
        this.colSpan = 12;
      } else if (width >= 1200 && width < 1600) {
        this.colSpan = 8;
      } else if (width >= 1600) {
        this.colSpan = 6;
      }
    },
    // 获取免审配置列表
    async fetchAuditConfigList() {
      this.loading = true;
      try {
        const { code, data, message } = await auditApi.fetchAuditConfigList({});

        if (code === 200) {
          this.list = data;
        } else {
          this.$message.error(message);
        }
        
        this.loading = false;
      } catch(e) {
        console.log(e);
        this.loading = false;
      }
    },
    jumpTopPage() {
      window.open(`${location.origin}/auditManage/auditConfigLog`);
    },
    openAddModal() {
      this.addVisible = true;
      this.selectedPrincipal = {};
      this.fetchPrincipalList();
    },
    // 获取主体列表
    async fetchPrincipalList() {
      try {
        const { code, data, message } = await trainingApi.getPrincipals();

        if (code === 200) {
          this.principalList = data;
        } else {
          this.$message.error(message);
        }
      } catch(e) {
        console.log(e);
      }
    },
    handleAddOk(formData) {
      const index = this.principalList.findIndex(item => item.id === formData.principal_id);
      this.selectedPrincipal = this.principalList[index];
      this.addVisible = false;
      this.$confirm({
        content: h => <div style="color:red;">新增/移除免审会影响主体（品牌）下所有经销商，谨慎操作！</div>,
        onOk: (close) => {
          this.addAuditConfig(close);
        }
      });
    },
    // 添加免审配置
    async addAuditConfig(close) {
      try {
        const { id, principal_name, avatar } = this.selectedPrincipal;
        const params = {
          principal_id: id,
          principal_name,
          avatar
        };
        const {code, message} = await auditApi.addAuditConfig(params);

        if (code === 200) {
          this.$message.success('添加免审成功');
          close();
          this.fetchAuditConfigList();
        } else {
          this.$message.error(message)
        }
      } catch(e) {
        console.log(e);
      }
    },
    handleAddCancel() {
      this.addVisible = false;
    },
    openRemoveModal(item) {
      this.selectedPrincipal = item;
      this.removeVisible = true;
    },
    handleRemoveOk() {
      this.removeVisible = false;
      this.$confirm({
        content: h => <div style="color:red;">新增/移除免审会影响主体（品牌）下所有经销商，谨慎操作！</div>,
        onOk: (close) => {
          this.removeAuditConfig(close);
        }
      });
    },
    // 移除免审配置
    async removeAuditConfig(close) {
      try {
        const params = {
          id: this.selectedPrincipal.id
        };
        const { code, message } = await auditApi.removeAuditConfig(params);

        if (code === 200) {
          this.$message.success('取消免审成功');
          close();
          this.fetchAuditConfigList();
        } else {
          this.$message.error(message);
        }
      } catch(e) {
        console.log(e);
      }
    },
    handleRemoveCancel() {
      this.removeVisible = false;
    }
  }
}
</script>

<style lang="scss" scoped>
.mb-16 {
  margin-bottom: 16px;
}

.page {
  padding: 0 !important;
  background: #f0f2f5 !important;
}

.page-header {
  padding: 24px;
  background: #fff;
}

.title {
  font-size: 20px;
  font-weight: 600;
}

.tips-wrap {
  display: flex;
  align-items: center;

  .tips {
    flex: 1;
    margin-bottom: 0;
  }
}

.config-item {
  margin: 0 0 16px;
}

.add-btn {
  width: 100%;
  height: 171px;
}

.principal {
  display: flex;
  align-items: center;

  &__avatar {
    width: 64px;
  }

  &__content {
    flex: 1;
    padding: 0 0 0 16px;
  }

  &__name {
    margin: 0 0 8px;
    font-size: 16px;
    font-weight: bold;
  }
}

.ant-btn-danger {
  color: #ff7875;
}
</style>
