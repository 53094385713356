<template>
  <a-modal
    title="移除免审主体"
    :visible="visible"
    :mask-closable="false"
    @ok="handleOk"
    @cancel="handleCancel"
  >
    <div class="content">
      <a-row>
        <a-col class="col" :span="21" :offset="3">
          <div class="principal">
            <div class="principal__avatar">
              <a-avatar
                icon="user"
                shape="square"
                :size="64"
                :src="`${ossHost3}/${principal.avatar}`"
              />
            </div>
            <div class="principal__content">
              <div class="principal__name">
                {{ principal.principal_name }}
              </div>
              <div class="principal__id">
                ID：{{ principal.principal_id }}
              </div>
            </div>
          </div>
        </a-col>
        <a-col class="col" :span="21" :offset="3">
          <p class="tips">移除后后续新添加媒体号都需要人工审核。</p>
        </a-col>
      </a-row>
    </div>
  </a-modal>
</template>

<script>
const ossHost3 = process.env.VUE_APP_OSS_HOST3;

export default {
  name: "RemoveAuditModal",
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    principal: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      ossHost3
    };
  },
  methods: {
    handleOk() {
      this.$emit("ok");
    },
    handleCancel() {
      this.$emit("cancel");
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  .col {
    margin-bottom: 24px;
  }

  .principal {
    display: flex;
    align-items: center;

    &__avatar {
      width: 64px;
    }

    &__content {
      flex: 1;
      padding: 0 0 0 16px;
    }

    &__name {
      margin: 0 0 8px;
      font-size: 16px;
      font-weight: bold;
      line-height: 1.5;
    }

    &__id {
      line-height: 1.5;
    }
  }

  .tips {
    margin: 0;
    color: #ff7875;
    line-height: 1.5;
  }
}
</style>
